import Alpine from 'alpinejs'
import persist from '@alpinejs/persist'

window.Alpine = Alpine
Alpine.plugin(persist)
Alpine.start()

;(function () {
    class PollFrame extends HTMLElement {

        #self;
        #source;
        #timeoutID;

        constructor() {
            super();

            this.#self = this;
            this.#sleep();
        }

        #sleep() {
            this.#timeoutID = setTimeout(() => {
                this.refreshFrame()
            }, 3000);
        }

        refreshFrame() {
            let source = this.#self.dataset.source;

            clearTimeout(this.#timeoutID);

            return new Promise((resolve) => {
                fetch(source)
                    .then((response) => {
                        if (!response.ok) {
                            throw new Error(`HTTP error! Status: ${response.status}`);
                        }

                        return response.text()
                    })
                    .then((frame) => {
                        Idiomorph.morph(this.#self, frame, {morphStyle:'innerHTML',head:{style: 'morph'}});
                    })
                    .finally((response) => {
                        this.#sleep();
                        resolve();
                    });
            })
        }
    }

    class FrameAction extends HTMLElement {
        constructor() {
            super();

            let links = this.getElementsByTagName("a");

            for (const link of links) {
                link.onclick = (e) => {
                    e.preventDefault();

                    let url = this.dataset.url;
                    let method = this.dataset.method;

                    if (!method) {
                        method = 'POST';
                    }

                    let children = [...link.childNodes];
                    link.innerHTML = '<span class="icon is-small"><i class="fa-solid fa-spinner fa-spin-pulse"></i></span>';

                    fetch(url, {
                        method: method,
                    })
                        .then((response) => {
                            if (!response.ok) {
                                link.innerHTML = '<span class="icon is-small"><i class="fa-solid fa-triangle-exclamation"></i></span>';
                                this.firstChild.onclick = (e) => {
                                    e.preventDefault();
                                    link.replaceChildren(...children);
                                };

                                return response.json().then(data => {
                                    if (data.message && data.code) {
                                        throw new Error(`${data.code}: ${data.message}`);
                                    } else {
                                        throw new Error(`HTTP error! Status: ${response.status}`);
                                    }
                                })
                            }

                            let frame = document.getElementById(this.dataset.frame);
                            frame.refreshFrame().then(() => {
                                link.replaceChildren(...children);
                            });
                        });
                };
            }

        }
    }

    window.onload = (event) => {
        customElements.define("poll-frame", PollFrame);
        customElements.define("frame-action", FrameAction);
    }
})();
